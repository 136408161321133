import React from 'react'
import * as PropTypes from 'prop-types'
import cx from 'classnames'

/**
* Zanata logo that can be inverted and animated.
*/
class LogoLoader extends React.Component {
  static propTypes = {
    /**
     * Toggle whether the logo is invert or not for styling.
     */
    inverted: PropTypes.bool.isRequired,
    /**
     * Toggle whether the is loading or not. Default is 'false'
     */
    loading: PropTypes.bool.isRequired
  }

  render () {
    const {
      inverted,
      loading
    } = this.props

    const className = cx('logoLoader', {
      'logoLoader-inverted': inverted,
      'is-loading': loading
    })

    /* eslint-disable max-len  */
    return (
      <span className={className} title='Zanata'>
        <span className='logoLoader-logo'>
          <svg className='logoLoader-svg LogoLoader-circle' viewBox='0 0 512 512'>
            <g><path d='M256.040517,26.9020538 C129.377614,26.9020538 26.4050036,130.259367 26.4050036,257.280952 C26.4050036,384.302988 129.377614,487.660302 256.040517,487.660302 C382.703464,487.660302 485.676029,384.302988 485.676075,257.280952 C485.676029,130.259367 382.703464,26.9020538 256.040517,26.9020538 Z M256.040517,66.3898734 C361.786714,66.3898734 447.229385,152.099354 447.229431,258.282844 C447.229385,364.466785 361.786714,450.176266 256.040517,450.176266 C150.294319,450.176266 64.8516476,364.466785 64.8516476,258.282844 C64.8516476,152.099354 150.294319,66.3898734 256.040517,66.3898734 Z' id='Circle'></path></g>
          </svg>
          <svg className='logoLoader-svg LogoLoader-circlePulse' viewBox='0 0 512 512'>
            <g><path d='M256.040517,26.9020538 C129.377614,26.9020538 26.4050036,130.259367 26.4050036,257.280952 C26.4050036,384.302988 129.377614,487.660302 256.040517,487.660302 C382.703464,487.660302 485.676029,384.302988 485.676075,257.280952 C485.676029,130.259367 382.703464,26.9020538 256.040517,26.9020538 Z M256.040517,66.3898734 C361.786714,66.3898734 447.229385,152.099354 447.229431,258.282844 C447.229385,364.466785 361.786714,450.176266 256.040517,450.176266 C150.294319,450.176266 64.8516476,364.466785 64.8516476,258.282844 C64.8516476,152.099354 150.294319,66.3898734 256.040517,66.3898734 Z' id='Circle'></path></g>
          </svg>
          <svg className='logoLoader-svg logoLoader-z' viewBox='0 0 512 512'>
            <g>
              <g id='Shadow' transform='translate(26.377192, 25.222456)'>
                <mask id='mask-2' fill='white'>
                  <path id='path-1' className='LogoLoader-zMask' d='M229.663325,0.656527298 C102.978083,0.656527298 0.00506640616,104.009785 0.00502136579,231.056604 C0.00506640616,358.103874 102.978083,461.457132 229.663325,461.457132 C356.348567,461.457132 459.321583,358.103874 459.321628,231.056604 C459.321583,104.009785 356.348567,0.656527298 229.663325,0.656527298 L229.663325,0.656527298 Z'></path>
                </mask>
                <path d='M370.359682,205.004592 C370.359682,205.004592 324.991098,260.667923 280.946431,337.901127 L451.416772,516.800535 C451.416772,516.800535 291.528531,440.593803 125.652038,346.908664 C155.346887,295.399884 194.844863,243.276033 194.844863,243.276033 C194.844863,243.276033 114.213224,210.438853 4.56035982,152.392287 C52.1675405,80.5865855 110.747727,21.1381963 110.747727,21.1381963 C110.747727,21.1381963 231.539977,113.317873 370.359682,205.004592 Z' id='Z-shadow' opacity='0.1' fill='#000000' mask='url(#mask-2)'></path>
              </g>
              <path d='M381.953181,190.180235 C381.953181,190.180235 336.584597,245.843566 292.53993,323.07677 L463.010272,501.976178 C463.010272,501.976178 303.122031,425.769445 137.245537,332.084307 C166.940386,280.575527 206.438363,228.451676 206.438363,228.451676 C206.438363,228.451676 125.806723,195.614496 16.1538594,137.56793 C63.76104,65.7622284 122.341227,6.31383929 122.341227,6.31383929 C122.341227,6.31383929 243.133477,98.4935163 381.953181,190.180235 Z' id='Z'></path>
            </g>
          </svg>
        </span>
      </span>
    )
    /* eslint-enable max-len */
  }
}

export default LogoLoader
