export const TOGGLE_MT_MERGE_MODAL = 'TOGGLE_MT_MERGE_MODAL'
export const TOGGLE_TM_MERGE_MODAL = 'TOGGLE_TM_MERGE_MODAL'
export const VERSION_LOCALES_REQUEST = 'VERSION_LOCALES_REQUEST'
export const VERSION_LOCALES_SUCCESS = 'VERSION_LOCALES_SUCCESS'
export const VERSION_LOCALES_FAILURE = 'VERSION_LOCALES_FAILURE'
export const PROJECT_PAGE_REQUEST = 'PROJECT_PAGE_REQUEST'
export const PROJECT_PAGE_SUCCESS = 'PROJECT_PAGE_SUCCESS'
export const PROJECT_PAGE_FAILURE = 'PROJECT_PAGE_FAILURE'

export const VERSION_MT_MERGE_REQUEST = 'VERSION_MT_MERGE_REQUEST'
export const VERSION_MT_MERGE_SUCCESS = 'VERSION_MT_MERGE_SUCCESS'
export const VERSION_MT_MERGE_FAILURE = 'VERSION_MT_MERGE_FAILURE'

export const VERSION_TM_MERGE_REQUEST = 'VERSION_TM_MERGE_REQUEST'
export const VERSION_TM_MERGE_SUCCESS = 'VERSION_TM_MERGE_SUCCESS'
export const VERSION_TM_MERGE_FAILURE = 'VERSION_TM_MERGE_FAILURE'

export const QUERY_MT_MERGE_PROGRESS_REQUEST = 'QUERY_MT_MERGE_PROGRESS_REQUEST'
export const QUERY_MT_MERGE_PROGRESS_SUCCESS = 'QUERY_MT_MERGE_PROGRESS_SUCCESS'
export const QUERY_MT_MERGE_PROGRESS_FAILURE = 'QUERY_MT_MERGE_PROGRESS_FAILURE'

export const QUERY_TM_MERGE_PROGRESS_REQUEST = 'QUERY_TM_MERGE_PROGRESS_REQUEST'
export const QUERY_TM_MERGE_PROGRESS_SUCCESS = 'QUERY_TM_MERGE_PROGRESS_SUCCESS'
export const QUERY_TM_MERGE_PROGRESS_FAILURE = 'QUERY_TM_MERGE_PROGRESS_FAILURE'

export const TM_MERGE_CANCEL_REQUEST = 'TM_MERGE_CANCEL_REQUEST'
export const TM_MERGE_CANCEL_SUCCESS = 'TM_MERGE_CANCEL_SUCCESS'
export const TM_MERGE_CANCEL_FAILURE = 'TM_MERGE_CANCEL_FAILURE'

export const MT_MERGE_CANCEL_REQUEST = 'MT_MERGE_CANCEL_REQUEST'
export const MT_MERGE_CANCEL_SUCCESS = 'MT_MERGE_CANCEL_SUCCESS'
export const MT_MERGE_CANCEL_FAILURE = 'MT_MERGE_CANCEL_FAILURE'

export const MT_MERGE_PROCESS_FINISHED = 'MT_MERGE_PROCESS_FINISHED'
export const TM_MERGE_PROCESS_FINISHED = 'TM_MERGE_PROCESS_FINISHED'
