import React from 'react'
import { Component } from 'react'

class Sidebar extends Component {
  /* eslint-disable react/jsx-no-bind, no-return-assign */
  render () {
    return (
      <div id='pvSidebar'>

      </div>
    )
    /* eslint-enable react/jsx-no-bind, no-return-assign */
  }
}
export default Sidebar
